import { useSelector } from 'react-redux'

import { SEAM_HEIGHT, SEAM_WIDTH } from '../components/layout'
import { MiroData, Position } from '../data/miro-types'
import { DataReady } from '../data/data-state'
import { RootState } from '../state/store'

export const rowIndexToVh = (rowIndex: number): number => {
  if (rowIndex === 0) {
    return 0
  }
  return -(100 - SEAM_HEIGHT) * rowIndex
}
export const rowIndexToPx = (rowIndex: number): number =>
  (rowIndexToVh(rowIndex) / 100) * window.innerHeight

export const columnIndexToVw = (currentColumnIndex: number) => {
  if (currentColumnIndex === 0) {
    return 0
  }
  return -(100 - SEAM_WIDTH) * currentColumnIndex
}
export const columnIndexToPx = (columnIndex: number): number =>
  (columnIndexToVw(columnIndex) / 100) * window.innerWidth

export const isHorizontal = (
  nodePosition: Position,
  otherNodePosition: Position,
) =>
  Math.abs(nodePosition.row - otherNodePosition.row) <
  Math.abs(nodePosition.column - otherNodePosition.column)

export const useIsZoomNeutral = () => {
  const scale = useSelector(({ transform }: RootState) => transform.scale)

  return Math.abs(scale - 1) < 0.01
}

export const useIsTranslateNeutral = () => {
  const { translateX, translateY } = useSelector(
    ({ transform }: RootState) => transform,
  )
  const currentContentId = useSelector(
    (state: RootState) => state.history.currentContentId,
  )
  const miro = (useSelector(
    (state: RootState) => state.miro,
  ) as DataReady<MiroData>).data

  if (currentContentId === null) {
    return true
  }
  const currentTilePosition =
    miro.containers[miro.contents[currentContentId].tileId].position

  const translateNeutralCutoff = 0.2

  return (
    Math.abs(translateX - columnIndexToPx(currentTilePosition.column)) <
      window.innerWidth * translateNeutralCutoff &&
    Math.abs(translateY - rowIndexToPx(currentTilePosition.row)) <
      window.innerHeight * translateNeutralCutoff
  )
}
