import {
  MiroData,
  Position,
  PositionableNode,
  ReferenceCountedNode,
  Shape,
  TileNode,
} from './miro-types'

export const getContainer = (
  miro: MiroData,
  column: number,
  row: number,
): TileNode => miro.containers[miro.containerMatrix[row][column]]

export const getPosition = (miro: MiroData, node: PositionableNode): Position =>
  'position' in node ? node.position : miro.containers[node.tileId].position

export const getConnectionIdentifier = (
  from: ReferenceCountedNode<Shape>,
  to: ReferenceCountedNode<Shape>,
) => `${from.id}-${to.id}`

export const extractTextFromHTML = (html: string): string => {
  const element = document.createElement('div')
  element.innerHTML = html
  return element.innerText
}
