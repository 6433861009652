import React from 'react'
import styled from 'styled-components'
import FullscreenContainer from './FullscreenContainer'
import Content from './Content'

const LoadingContainer = styled(FullscreenContainer)`
  justify-content: center;
  align-items: center;
`

const LoadingPage = ({ description }: { description?: string }) => (
  <LoadingContainer>
    <Content>Loading{description && ` ${description}`}...</Content>
  </LoadingContainer>
)

export default LoadingPage
