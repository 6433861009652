import { createModel } from '@rematch/core'

import { RootModel } from './models'

interface DebugState {
  layoutOverlay: boolean
  transparentBlocks: boolean
  historyTrace: boolean
  keyboardNavigation: boolean
}

export const debug = createModel<RootModel>()({
  state: {
    layoutOverlay: false,
    transparentBlocks: false,
    historyTrace: false,
    keyboardNavigation: false,
  } as DebugState, // initial state
  reducers: {
    setLayoutOverlayEnabled(state, layoutOverlayEnabled: boolean): DebugState {
      return { ...state, layoutOverlay: layoutOverlayEnabled }
    },
    setTransparentBlocksEnabled(
      state,
      transparentBlocksEnabled: boolean,
    ): DebugState {
      return { ...state, transparentBlocks: transparentBlocksEnabled }
    },
    setHistoryTraceEnabled(state, historyTraceEnabled: boolean): DebugState {
      return { ...state, historyTrace: historyTraceEnabled }
    },
    setKeyboardNavigationEnabled(
      state,
      keyboardNavigationEnabled: boolean,
    ): DebugState {
      return { ...state, keyboardNavigation: keyboardNavigationEnabled }
    },
  },
})
