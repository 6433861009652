import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { Translations } from '../data/miro-types'

import ui_de from './de/ui.json'
import ui_en from './en/ui.json'

const initializeI18n = async () => {
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: process.env.NODE_ENV === 'development',

      interpolation: {
        escapeValue: false, // not needed for React as it escapes by default
      },
      ns: ['ui', 'miro'],
      defaultNS: 'ui',
      resources: {
        en: {
          ui: ui_en,
        },
        de: {
          ui: ui_de,
        },
      },
    })
}

export const addMiroTranslations = (translations: Translations) => {
  Object.entries(translations).forEach(([language, translation]) => {
    i18n.addResources(language, 'miro', translation)
  })
}

export default initializeI18n
