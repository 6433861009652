export enum MiroTypes {
  Shape = 'SHAPE',
  Line = 'LINE',
}

interface BaseMiroElement {
  id: string
}

export type Shape = BaseMiroElement & {
  type: MiroTypes.Shape
  style: CSSStyleDeclaration & { shapeType: number }
  text: string
  x: number
  y: number
  height: number
  width: number
}
export type Line = BaseMiroElement & {
  type: MiroTypes.Line
  endWidgetId: string
  startWidgetId: string
}
type MiroElement = Shape | Line

export type RawMiroData = Array<MiroElement>

export interface Position {
  column: number
  row: number
}

export enum ReferenceCounterPosition {
  Top = 'top',
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
}

export type ReferenceCounters = {
  [position in ReferenceCounterPosition]: string[]
}
export type ReferenceCountedNode<RawType> = BaseNode<RawType> & {
  references: ReferenceCounters
}
export const generateEmptyReferences = () => ({
  [ReferenceCounterPosition.Top]: [],
  [ReferenceCounterPosition.Left]: [],
  [ReferenceCounterPosition.Right]: [],
  [ReferenceCounterPosition.Bottom]: [],
})

export interface BaseNode<RawType> {
  id: string
  raw?: RawType
}

export interface PositionedNode {
  position: Position
}

export enum ContentTypes {
  Question,
  Artwork,
  Interactive,
  Comment,
}
export type BaseContentNode = ReferenceCountedNode<Shape> & {
  type: ContentTypes
  answerIds: string[]
  tileId: string
}
export type QuestionNode = BaseContentNode & { type: ContentTypes.Question }
export type ArtworkNode = BaseContentNode & {
  type: ContentTypes.Artwork
}
export enum InteractiveComponent {
  UNKNOWN,
  LOGIN,
  VIDEO,
  IMAGE,
  AUDIO,
  DISCUSSION,
  TITLE,
  SIGNUP,
  ARTWORK_INTRO,
}
export enum LogInType {
  CARDBOARD,
  EMAIL,
  MOBILE,
}
export type BaseInteractiveNode = BaseContentNode & {
  type: ContentTypes.Interactive
  component: InteractiveComponent
}
export type UnknownInteractiveNode = BaseInteractiveNode & {
  component: InteractiveComponent.UNKNOWN
}
export type LoginNode = BaseInteractiveNode & {
  component: InteractiveComponent.LOGIN
  logInType: LogInType
}
export type VideoNode = BaseInteractiveNode & {
  component: InteractiveComponent.VIDEO
}
export type ImageNode = BaseInteractiveNode & {
  component: InteractiveComponent.IMAGE
  fileName: string
  fileExtensions: Array<string>
  flags: Array<string>
}
export type AudioNode = BaseInteractiveNode & {
  component: InteractiveComponent.AUDIO
  fileName: string
  fileExtensions: Array<string>
  flags: Array<string>
}
export type DiscussionNode = BaseInteractiveNode & {
  component: InteractiveComponent.DISCUSSION
}
export type TitleNode = BaseInteractiveNode & {
  component: InteractiveComponent.TITLE
}
export type SignupNode = BaseInteractiveNode & {
  component: InteractiveComponent.SIGNUP
  incremental: boolean
}
export type ArtworkIntroNode = BaseInteractiveNode & {
  component: InteractiveComponent.ARTWORK_INTRO
}
export type InteractiveNode =
  | UnknownInteractiveNode
  | LoginNode
  | VideoNode
  | ImageNode
  | AudioNode
  | DiscussionNode
  | TitleNode
  | SignupNode
  | ArtworkIntroNode

export type CommentNode = BaseContentNode & {
  type: ContentTypes.Comment
}
export type ContentNode =
  | QuestionNode
  | ArtworkNode
  | InteractiveNode
  | CommentNode

export type AnswerNode = BaseNode<Shape> &
  PositionedNode &
  ReferenceCountedNode<Shape> & {
    action: string | null
    nextNodeId: string
    previousNodeId: string
    order: number
    unavoidable: boolean
  }
export interface SeamAnswerTensor {
  [columnIndex: number]: {
    [rowIndex: number]: Array<string>
  }
}
export type TileNode = BaseNode<Shape> &
  PositionedNode & {
    contentNodeIds: string[]
    layoutHints: Array<string>
  }
export type PositionableNode = ContentNode | AnswerNode

export interface NodeMap<NodeType> {
  [nodeId: string]: NodeType
}

export interface Translations {
  en: { [key: string]: string }
  de: { [key: string]: string }
}

export interface MiroData {
  columnCount: number
  rowCount: number
  containers: NodeMap<TileNode>
  containerMatrix: Array<Array<string>>
  startContainerId: string
  answers: NodeMap<AnswerNode>
  seamAnswerTensor: SeamAnswerTensor
  questionIds: Array<string>
  artworkIds: Array<string>
  interactiveIds: Array<string>
  commentIds: Array<string>
  contents: NodeMap<ContentNode>

  translations?: Translations
}
