import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import initializeI18n from './i18n/initialize'
import { store } from './state/store'
import { host, origin } from './util/backendUrl'
import { Paths } from './routes/Navigation'
import ErrorBoundary from './components/common/ErrorBoundary'
import LoadingPage from './components/common/LoadingPage'

import './index.css'
import reportWebVitals from './reportWebVitals'

initializeI18n()

const httpLink = new HttpLink({
  uri: `${origin}/graphql`,
  fetchOptions: {
    credentials: 'include',
  },
})

const wsLink = new WebSocketLink({
  uri: `${
    window.location.protocol === 'https:' ? 'wss' : 'ws'
  }:${host}/subscriptions`,
  options: {
    reconnect: true,
  },
})
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)
const cache = new InMemoryCache()
const client = new ApolloClient({
  cache: cache,
  link: splitLink,
})
window.apolloClient = client // hack: make apollo client available to state management for side effects

const Navigation = lazy(() => import('./routes/Navigation'))
const paths: Paths = {
  doubt: lazy(() => import('./routes/Doubt')),
  look: lazy(() => import('./routes/Look')),
  cyber: lazy(() => import('./routes/Cyber')),
  placeholder: lazy(() => import('./routes/Placeholder')),
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<LoadingPage description='application' />}>
            <ErrorBoundary>
              <Switch>
                {Object.entries(paths).map(([path, Component]) => {
                  if (
                    process.env.REACT_APP_EXCLUSIVE_PATH &&
                    process.env.REACT_APP_EXCLUSIVE_PATH !== path
                  ) {
                    return null
                  }

                  return (
                    <Route
                      key={path}
                      exact
                      path={
                        process.env.REACT_APP_EXCLUSIVE_PATH ? '/' : `/${path}`
                      }
                      component={Component}
                    />
                  )
                })}
                {!process.env.REACT_APP_EXCLUSIVE_PATH && (
                  <Route exact path='/'>
                    <Navigation paths={paths} />
                  </Route>
                )}
              </Switch>
            </ErrorBoundary>
          </Suspense>
        </Router>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
