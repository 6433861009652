import { gql } from '@apollo/client'

export const SET_MOBILE_SETUP_MUTATION = gql`
  mutation SetMobileSetup($userId: String!, $field: String!, $value: String!) {
    setMobileSetup(userId: $userId, field: $field, value: $value) {
      _id
      userAgent
      youTubeInstalled
      cardboardInstalled
      videoTested
      audioTested
    }
  }
`

export const GET_USER_QUERY = gql`
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      _id
      userAgent
      youTubeInstalled
      cardboardInstalled
      videoTested
      audioTested
      currentArtwork
    }
  }
`
