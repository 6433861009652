import React from 'react'
import { createModel } from '@rematch/core'

import {
  DataReady,
  DataState,
  InitialState,
  Loadable,
} from '../data/data-state'
import MiroLoader, { MISSING_LINK } from '../data/miro-loader'
import { MiroData, Translations } from '../data/miro-types'
import { addMiroTranslations } from '../i18n/initialize'
import { RootModel } from './models'

export const MiroContext = React.createContext<MiroData>(null as any)

export const miro = createModel<RootModel>()({
  state: InitialState as Loadable<MiroData>, // initial state
  reducers: {
    setLoading(state, _: void) {
      return { ...state, state: DataState.LOADING }
    },
    setData(state, miro: MiroData) {
      const {
        answers,
        seamAnswerTensor,
        artworkIds,
        columnCount,
        containerMatrix,
        containers,
        startContainerId,
        contents,
        commentIds,
        interactiveIds,
        questionIds,
        rowCount,
        translations,
      } = miro
      return {
        state: DataState.READY,
        data: {
          answers,
          seamAnswerTensor,
          artworkIds,
          columnCount,
          containerMatrix,
          containers,
          startContainerId,
          contents,
          commentIds,
          interactiveIds,
          questionIds,
          rowCount,
          translations,
        },
      }
    },
    setError(state, error: string) {
      return {
        state: DataState.ERRORED,
        error,
      }
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async load() {
      dispatch.miro.setLoading()
      let translations: Translations
      let miroData: MiroData
      if (process.env.REACT_APP_MIRO_PROXY_URL) {
        try {
          const miro = new MiroLoader()
          await miro.load(process.env.REACT_APP_MIRO_PROXY_URL)

          miroData = miro
          translations = miro.translations
        } catch (error) {
          console.error(
            `[state/miro.effects.load] Error while loading Miro:`,
            error,
          )
          dispatch.miro.setError(error.toString())
          return
        }
      } else {
        ;({ translations, ...miroData } = await import(
          '../data/miro-data.json'
        ))
      }

      addMiroTranslations(translations)

      dispatch.miro.setData(miroData)
    },
    async activateFirstContent(_: void, state) {
      const miro = (state.miro as DataReady<MiroData>).data
      if (miro.startContainerId === MISSING_LINK) {
        throw Error(`No start container ID set!`)
      }

      const firstContent =
        miro.contents[
          miro.containers[
            miro.startContainerId
          ].contentNodeIds.find((contentNodeId) =>
            miro.questionIds.includes(contentNodeId),
          )!
        ]

      dispatch.history.goToContent({
        contentId: firstContent.id,
        animation: false,
      })
    },
  }),
})
