import { createModel } from '@rematch/core'
import { gql } from '@apollo/client'

import { isAndroid, isIOS } from '../util/feature-detection'
import { RootModel } from './models'
import {
  MobileSetup as MobileSetupMutation,
  MobileSetup_mobileSetup,
  MobileSetupVariables,
} from '../generated/MobileSetup'
import { observeStore } from './store'

const MOBILE_SETUP_SUBSCRIPTION = gql`
  subscription MobileSetup($userId: String!) {
    mobileSetup(userId: $userId) {
      _id
      userAgent
      youTubeInstalled
      cardboardInstalled
      videoTested
      audioTested
    }
  }
`

export enum SetupStates {
  Initial = 'INITIAL',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}
interface MobileSetup {
  userAgent: string
  youTubeInstalled: SetupStates
  cardboardInstalled: SetupStates
  videoTested: SetupStates
  audioTested: SetupStates
  subscribed: boolean
}

export interface UserState {
  _id: string | null
  firstname: string | null
  isMobile: boolean
  token: string | null
  signup: boolean
  mobileSetup: MobileSetup
}

export const initialUserState: UserState = {
  _id: null,
  firstname: null,
  isMobile: isAndroid || isIOS,
  token: null,
  signup: false,
  mobileSetup: {
    userAgent: '',
    youTubeInstalled: SetupStates.Initial,
    cardboardInstalled: SetupStates.Initial,
    videoTested: SetupStates.Initial,
    audioTested: SetupStates.Initial,
    subscribed: false,
  },
}

export const user = createModel<RootModel>()({
  state: initialUserState,
  reducers: {
    setUser(
      state,
      {
        _id,
        firstname,
        token,
        userAgent,
        youTubeInstalled,
        cardboardInstalled,
        videoTested,
        audioTested,
      }: {
        _id: string
        firstname: string
        token: string
        userAgent: string
        youTubeInstalled: string
        cardboardInstalled: string
        videoTested: string
        audioTested: string
      },
    ) {
      return {
        ...state,
        _id,
        firstname,
        token,
        mobileSetup: {
          subscribed: state.mobileSetup.subscribed,
          userAgent: userAgent as SetupStates,
          youTubeInstalled: youTubeInstalled as SetupStates,
          cardboardInstalled: cardboardInstalled as SetupStates,
          videoTested: videoTested as SetupStates,
          audioTested: audioTested as SetupStates,
        },
      }
    },
    resetUser(state) {
      return initialUserState
    },
    setIsMobile(state, isMobile: boolean) {
      return { ...state, isMobile }
    },
    setSignup(
      state,
      { signup, firstname }: { signup: boolean; firstname: string | null },
    ) {
      const nextState = { ...state, signup, firstname }
      if (firstname === null) {
        return { ...nextState, _id: null, token: null }
      }
      return nextState
    },
    setSubscribedMobileSetup(state, subscribed: boolean) {
      return {
        ...state,
        mobileSetup: {
          ...state.mobileSetup,
          subscribed,
        },
      }
    },
    setMobileSetup(state, mobileSetup: MobileSetup) {
      return {
        ...state,
        mobileSetup,
      }
    },
  },
  effects: (dispatch) => ({
    subscribeMobileSetup(_: void, state) {
      if (!state.user._id) {
        throw Error(
          '[state/user.effects.subscribeMobileSetup] No user ID set in state.',
        )
      }
      dispatch.user.setSubscribedMobileSetup(true)
      const subscription = window.apolloClient
        .subscribe<MobileSetupMutation, MobileSetupVariables>({
          query: MOBILE_SETUP_SUBSCRIPTION,
          variables: {
            userId: state.user._id,
          },
        })
        .subscribe({
          next({
            data: {
              mobileSetup: {
                userAgent,
                youTubeInstalled,
                cardboardInstalled,
                videoTested,
                audioTested,
              },
            },
          }: {
            data: { mobileSetup: MobileSetup_mobileSetup }
          }) {
            dispatch.user.setMobileSetup({
              subscribed: true,
              userAgent: userAgent,
              youTubeInstalled: youTubeInstalled as SetupStates,
              cardboardInstalled: cardboardInstalled as SetupStates,
              videoTested: videoTested as SetupStates,
              audioTested: audioTested as SetupStates,
            })
          },
          error(error: any) {
            console.error('err', error)
          },
        })

      observeStore<MobileSetup>(
        (observedState) => observedState.user.mobileSetup,
        (observedTransformState) => {
          if (!observedTransformState.subscribed) {
            subscription.unsubscribe()
          }
        },
      )
    },
  }),
})
