export function eventClientX(
  event: TouchEvent | MouseEvent | WheelEvent,
): number {
  if (event instanceof MouseEvent || event instanceof WheelEvent) {
    return event.clientX
  } else {
    return event.changedTouches[0].clientX
  }
}

export function eventClientY(
  event: TouchEvent | MouseEvent | WheelEvent,
): number {
  if (event instanceof MouseEvent || event instanceof WheelEvent) {
    return event.clientY
  } else {
    return event.changedTouches[0].clientY
  }
}

export function isMultiTouch(event: Event): boolean {
  return (
    window.TouchEvent && event instanceof TouchEvent && event.touches.length > 1
  )
}
