export enum HorizontalAlign {
  'left',
  'right',
}
export enum VerticalAlign {
  'top',
  'bottom',
}
export enum TravelState {
  ACTIVE = 'active',
  PRISTINE = 'pristine',
  TRAVELLED = 'travelled',
  BYPASSED = 'bypassed',
}

export const SEAM_WIDTH = 20
export const SEAM_HEIGHT = 20
export const TILE_WIDTH = 100 - 2 * SEAM_WIDTH
export const TILE_HEIGHT = 100 - 2 * SEAM_HEIGHT

export const BASE_FONT = `'Inconsolata', sans-serif`
export const TITLE_FONT = `'Glacial Indifference', sans-serif`

export const RED = '#cd0707'
export const BLUE = '#006d95'
export const GRAY_LIGHT = '#eeeeee'
export const GRAY_MEDIUM = 'gray'
export const GRAY_DARK = '#333333'

export const LINE_COLOR: { [travelState in TravelState]: string } = {
  [TravelState.ACTIVE]: GRAY_LIGHT,
  [TravelState.PRISTINE]: GRAY_DARK,
  [TravelState.TRAVELLED]: RED,
  [TravelState.BYPASSED]: GRAY_DARK,
}

export const MARKER_HEIGHT = 10
export const MARKER_LENGTH = 16
export const markerArrowHeadId = (travelState: TravelState) =>
  `arrow-head-${travelState}`

export const BASE_SHADOW_OFFSET = 0.2 // em
export const BASE_SHADOW_OFFSET_PIXEL_HACK =
  BASE_SHADOW_OFFSET *
  parseFloat(getComputedStyle(document.documentElement).fontSize)
export const TEXT_SHADOW_COEFFICIENT = 0.25

export const BORDER_WIDTH = '2px'
export const ANSWER_BORDER_RADIUS = '2em'

export const shadow = (
  color: typeof RED | typeof BLUE | typeof GRAY_MEDIUM | typeof GRAY_DARK,
  distanceCoefficient = 1,
) =>
  `${color === RED ? '' : '-'}${distanceCoefficient * BASE_SHADOW_OFFSET}em ${
    distanceCoefficient * BASE_SHADOW_OFFSET
  }em 0 ${color}`

export const SHADOW_ANIMATION_DURATION = '3s'

export enum DEBUG_CLASSES {
  'OVERLAY' = 'debug-overlay',
  'TRANSPARENT_BLOCKS' = 'debug-transparent-blocks',
}
