import { isHorizontal } from '../util/grid'
import {
  AnswerNode,
  ContentNode,
  ContentTypes,
  generateEmptyReferences,
  InteractiveComponent,
  InteractiveNode,
  Line,
  LogInType,
  MiroData,
  MiroTypes,
  NodeMap,
  Position,
  PositionableNode,
  RawMiroData,
  ReferenceCounterPosition,
  SeamAnswerTensor,
  Shape,
  TileNode,
  Translations,
} from './miro-types'
import {
  extractTextFromHTML,
  getConnectionIdentifier,
  getContainer,
  getPosition,
} from './miro-util'

export const MISSING_LINK = 'MISSING_LINK'
export const MISSING_NAME = 'MISSING'

class MiroLoader implements MiroData {
  static Colors = {
    CONTAINER: 'transparent',
    QUESTION: '#ffe432',
    ARTWORK: '#000000',
    ANSWER: '#9ee1ff',
    INTERACTIVE: '#fd4848',
    COMMENT: '#e6e6e6',
    UNAVOIDABLE: '#7dd531',
  }

  columnCount: number = 0
  rowCount: number = 0
  containers: NodeMap<Required<TileNode>> = {}
  containerMatrix: Array<Array<string>> = []
  startContainerId: string = MISSING_LINK
  answers: NodeMap<AnswerNode> = {}
  seamAnswerTensor: SeamAnswerTensor = {}
  questionIds: Array<string> = []
  artworkIds: Array<string> = []
  interactiveIds: Array<string> = []
  commentIds: Array<string> = []
  contents: NodeMap<ContentNode> = {}
  translations: Translations = { en: {}, de: {} }

  private async request(url: string): Promise<RawMiroData> {
    const response = await fetch(url)
    if (!response.ok) {
      console.error(
        '[MiroLoader.request] Failed!',
        response.status,
        await response.text(),
      )
      throw Error('MiroLoader request failed.')
    }
    return response.json()
  }

  private static splitLines(text: string): Array<string> {
    return text
      .replace(/<p>/g, '')
      .split('</p>')
      .map((line) => line.replace(/<\/?span>/g, ''))
      .filter((line) => line.length > 0)
  }
  private getContainer(column: number, row: number): Required<TileNode> {
    return getContainer(this, column, row) as Required<TileNode>
  }

  private isWithinX(innerShape: Shape, outerShape: Shape): boolean {
    return (
      outerShape.x - outerShape.width / 2 <
        innerShape.x - innerShape.width / 2 &&
      outerShape.x + outerShape.width / 2 > innerShape.x + innerShape.width / 2
    )
  }
  private isWithinY(innerShape: Shape, outerShape: Shape): boolean {
    return (
      outerShape.y - outerShape.height / 2 <
        innerShape.y - innerShape.height / 2 &&
      outerShape.y + outerShape.height / 2 >
        innerShape.y + innerShape.height / 2
    )
  }
  private isWithin(innerShape: Shape, outerShape: Shape): boolean {
    return (
      this.isWithinX(innerShape, outerShape) &&
      this.isWithinY(innerShape, outerShape)
    )
  }
  private isWithinColumn(shape: Shape, column: number): boolean {
    const columnShape = this.containers[this.containerMatrix[0][column]].raw
    return this.isWithinX(shape, columnShape)
  }
  private isWithinRow(shape: Shape, row: number): boolean {
    const columnShape = this.containers[this.containerMatrix[row][0]].raw
    return this.isWithinY(shape, columnShape)
  }

  private locateAnswer(rawAnswer: Shape): Position {
    let column: number | null = null
    for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
      if (this.isWithinColumn(rawAnswer, columnIndex)) {
        column = columnIndex
        break
      }
    }
    if (column === null) {
      for (
        let columnIndex = 0;
        columnIndex < this.columnCount - 1;
        columnIndex++
      ) {
        const containerLeft = this.getContainer(columnIndex, 0)
        const containerRight = this.getContainer(columnIndex + 1, 0)
        if (
          containerLeft.raw.x < rawAnswer.x &&
          rawAnswer.x < containerRight.raw.x
        ) {
          column = columnIndex + 0.5
          break
        }
      }
    }
    if (column === null) {
      console.error(rawAnswer)
      throw Error(
        `[Miro.locateAnswer] Answer ${rawAnswer.id} couldn't be located on grid (column)`,
      )
    }

    let row: number | null = null
    for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
      if (this.isWithinRow(rawAnswer, rowIndex)) {
        row = rowIndex
        break
      }
    }
    if (row === null) {
      for (let rowIndex = 0; rowIndex < this.rowCount - 1; rowIndex++) {
        const containerAbove = this.getContainer(0, rowIndex)
        const containerBelow = this.getContainer(0, rowIndex + 1)
        if (
          containerAbove.raw.y < rawAnswer.y &&
          rawAnswer.y < containerBelow.raw.y
        ) {
          row = rowIndex + 0.5
          break
        }
      }
    }
    if (row === null) {
      console.error(rawAnswer)
      throw Error(
        `[Miro.locateAnswer] Answer ${rawAnswer.id} couldn't be located on grid (row)`,
      )
    }

    return { column, row }
  }

  private static compareAnswersWithinSeam(
    answerA: AnswerNode,
    answerB: AnswerNode,
  ) {
    if (answerA.position.row % 1 !== 0) {
      // horizontal seam
      return answerA.raw!.x - answerB.raw!.x
    } else {
      return answerA.raw!.y - answerB.raw!.y
    }
  }

  private addReferenceCounts(
    from: PositionableNode,
    to: PositionableNode,
  ): {
    fromConnectionPosition: ReferenceCounterPosition
    toConnectionPosition: ReferenceCounterPosition
  } {
    const fromPosition = getPosition(this, from)
    const toPosition = getPosition(this, to)

    let fromConnectionPosition
    let toConnectionPosition
    if (isHorizontal(fromPosition, toPosition)) {
      if (fromPosition.column < toPosition.column) {
        fromConnectionPosition = ReferenceCounterPosition.Right
        toConnectionPosition = ReferenceCounterPosition.Left
      } else {
        fromConnectionPosition = ReferenceCounterPosition.Left
        toConnectionPosition = ReferenceCounterPosition.Right
      }
    } else {
      if (fromPosition.row < toPosition.row) {
        fromConnectionPosition = ReferenceCounterPosition.Bottom
        toConnectionPosition = ReferenceCounterPosition.Top
      } else {
        fromConnectionPosition = ReferenceCounterPosition.Top
        toConnectionPosition = ReferenceCounterPosition.Bottom
      }
    }

    const connectionIdentifier = getConnectionIdentifier(from, to)
    from.references[fromConnectionPosition].push(connectionIdentifier)
    to.references[toConnectionPosition].push(connectionIdentifier)

    return { fromConnectionPosition, toConnectionPosition }
  }

  private addTranslations(id: string, rawText: string) {
    let translations = rawText.replace(/\\/g, '/').split('|')
    if (translations[0]) {
      this.translations.en[id] = translations[0].trim()
    }
    if (translations[1]) {
      this.translations.de[id] = translations[1].trim()
    }
  }

  private processData(rawData: RawMiroData) {
    const rawConnections: Line[] = []
    const rawContainers: Shape[] = []
    const rawAnswers: Shape[] = []
    const rawContents: Shape[] = []
    const unclassified: Shape[] = []
    rawData.forEach((entry) => {
      if (entry.type === MiroTypes.Line) {
        rawConnections.push(entry)
        return
      }
      if (entry.type === MiroTypes.Shape) {
        if (entry.style.shapeType === 4) {
          return
        }
        if (entry.style.backgroundColor === MiroLoader.Colors.CONTAINER) {
          rawContainers.push(entry)
          return
        }
        if (entry.style.backgroundColor === MiroLoader.Colors.ANSWER) {
          rawAnswers.push(entry)
          return
        }
        if (
          [
            MiroLoader.Colors.QUESTION,
            MiroLoader.Colors.ARTWORK,
            MiroLoader.Colors.INTERACTIVE,
            MiroLoader.Colors.COMMENT,
          ].includes(entry.style.backgroundColor)
        ) {
          rawContents.push(entry)
          return
        }
      }
      unclassified.push(entry)
    })
    rawContainers.sort((a, b) => {
      if (Math.abs(a.y - b.y) > a.height / 2) {
        return a.y - b.y
      }
      return a.x - b.x
    })
    if (process.env.NODE_ENV === 'development') {
      console.log('[MiroLoader.processData] Raw containers:', rawContainers)
      console.log('[MiroLoader.processData] Raw contents:', rawContents)
      console.log('[MiroLoader.processData] Raw answers:', rawAnswers)
      console.log('[MiroLoader.processData] Raw connections:', rawConnections)
      console.log('[MiroLoader.processData] Unclassified:', unclassified)
    }

    // const connections: NodeMap<> = []
    let rowVector: Array<string> = []

    let row = -1
    let column = 0
    rawContainers.forEach((rawContainer, index) => {
      if (
        index === 0 ||
        rawContainer.y - rawContainer.height / 2 > rawContainers[index - 1].y
      ) {
        row += 1
        column = 0
        rowVector = [rawContainer.id]
        this.containerMatrix.push(rowVector)
      } else {
        column += 1
        rowVector.push(rawContainer.id)
      }

      let layoutHints: Array<string> = []
      const text = extractTextFromHTML(rawContainer.text)
      if (text.includes('{')) {
        layoutHints = text
          .replace(/[^{]*{/, '')
          .replace(/}.*/, '')
          .split(',')
      }
      this.containers[rawContainer.id] = {
        id: rawContainer.id,
        position: {
          row,
          column,
        },
        contentNodeIds: [],
        layoutHints,
        raw: rawContainer,
      }

      if (rawContainer.style.borderColor === MiroLoader.Colors.INTERACTIVE) {
        this.startContainerId = rawContainer.id
      }
    })

    this.rowCount = row + 1
    this.columnCount = column + 1
    rawContents.sort(
      (rawContentA, rawContentB) =>
        Math.hypot(rawContentA.x, rawContentA.y) -
        Math.hypot(rawContentB.x, rawContentB.y),
    )

    rawContents.forEach((rawContent) => {
      const host = rawContainers.find((rawContainer) =>
        this.isWithin(rawContent, rawContainer),
      )
      if (!host) {
        console.error(
          `[Miro.processData] Question (${rawContent.id}) without matching container`,
          rawContent,
        )
        return
      }
      this.containers[host.id].contentNodeIds.push(rawContent.id)
      const baseContent: Omit<ContentNode, 'type'> = {
        id: rawContent.id,
        tileId: host.id,
        answerIds: [],
        raw: rawContent,
        references: generateEmptyReferences(),
      }
      if (rawContent.style.backgroundColor === MiroLoader.Colors.ARTWORK) {
        try {
          const [title, artist, youTubeId] = MiroLoader.splitLines(
            rawContent.text,
          )

          this.addTranslations(
            `${rawContent.id}-title`,
            extractTextFromHTML(title),
          )
          this.addTranslations(
            `${rawContent.id}-artist`,
            extractTextFromHTML(artist),
          )
          this.addTranslations(
            `${rawContent.id}-youTubeId`,
            extractTextFromHTML(youTubeId),
          )
          this.contents[rawContent.id] = {
            ...baseContent,
            type: ContentTypes.Artwork,
          }
          this.artworkIds.push(rawContent.id)
        } catch (error) {
          console.error('Exception', error)
          console.error('Raw node', rawContent)
          throw Error(
            `[Miro.processData] Failed to process artwork ${rawContent.id}!`,
          )
        }
      } else if (
        rawContent.style.backgroundColor === MiroLoader.Colors.INTERACTIVE
      ) {
        try {
          let component: InteractiveComponent = InteractiveComponent.UNKNOWN
          let additionalProperties: any = {}
          if (rawContent.text.includes('LOGIN')) {
            component = InteractiveComponent.LOGIN
            const [, rawLoginType] = MiroLoader.splitLines(rawContent.text)
            additionalProperties.logInType =
              rawLoginType === 'cardboard'
                ? LogInType.CARDBOARD
                : LogInType.EMAIL
          } else if (rawContent.text.includes('VIDEO')) {
            component = InteractiveComponent.VIDEO
            const [, source] = MiroLoader.splitLines(rawContent.text)
            this.addTranslations(`${rawContent.id}-vimeoId`, source)
          } else if (rawContent.text.includes('IMAGE')) {
            component = InteractiveComponent.IMAGE
            const [, fileName, fileExtensions, flags] = MiroLoader.splitLines(
              rawContent.text,
            )
            additionalProperties.fileName = fileName
            additionalProperties.fileExtensions = fileExtensions.split(',')
            additionalProperties.flags = (flags || '').split(',')
          } else if (rawContent.text.includes('AUDIO')) {
            component = InteractiveComponent.AUDIO
            const [
              ,
              fileName,
              fileExtensions,
              flags,
              text,
            ] = MiroLoader.splitLines(rawContent.text)
            additionalProperties.fileName = fileName
            additionalProperties.fileExtensions = fileExtensions.split(',')
            additionalProperties.flags = (flags || '').split(',')
            this.addTranslations(rawContent.id, extractTextFromHTML(text))
          } else if (rawContent.text.includes('DISCUSSION')) {
            component = InteractiveComponent.DISCUSSION
            this.addTranslations(
              rawContent.id,
              MiroLoader.splitLines(rawContent.text)[1],
            )
          } else if (rawContent.text.includes('TITLE')) {
            component = InteractiveComponent.TITLE
          } else if (rawContent.text.includes('SIGNUP')) {
            component = InteractiveComponent.SIGNUP
            const [, options] = MiroLoader.splitLines(rawContent.text)
            additionalProperties.incremental = (options || '').includes(
              'incremental',
            )
          } else if (rawContent.text.includes('ARTWORK_INTRO')) {
            const [, title, artist] = MiroLoader.splitLines(rawContent.text)
            this.addTranslations(
              `${rawContent.id}-title`,
              extractTextFromHTML(title),
            )
            this.addTranslations(
              `${rawContent.id}-artist`,
              extractTextFromHTML(artist),
            )

            component = InteractiveComponent.ARTWORK_INTRO
          } else {
            console.error(
              `[Miro.processData] Unknown interactive component: ${rawContent.text}`,
            )
          }
          this.contents[rawContent.id] = {
            ...baseContent,
            type: ContentTypes.Interactive,
            component,
            ...additionalProperties,
          } as InteractiveNode
          this.interactiveIds.push(rawContent.id)
        } catch (error) {
          console.error('Exception', error)
          console.error('Raw node', rawContent)
          throw Error(
            `[Miro.processData] Failed to process interactive content ${rawContent.id}!`,
          )
        }
      } else if (
        rawContent.style.backgroundColor === MiroLoader.Colors.QUESTION
      ) {
        this.addTranslations(
          rawContent.id,
          extractTextFromHTML(rawContent.text),
        )
        this.contents[rawContent.id] = {
          ...baseContent,
          type: ContentTypes.Question,
        }
        this.questionIds.push(rawContent.id)
      } else if (
        rawContent.style.backgroundColor === MiroLoader.Colors.COMMENT
      ) {
        const text = extractTextFromHTML(rawContent.text)
        const [title, body] = text.split('::')
        this.addTranslations(rawContent.id + '-title', title)
        this.addTranslations(rawContent.id + '-body', body)
        this.contents[rawContent.id] = {
          ...baseContent,
          type: ContentTypes.Comment,
        }
        this.commentIds.push(rawContent.id)
      } else {
        throw Error(
          `[Miro.processData] Unknown content: ${rawContent.text} (${rawContent.id}, background ${rawContent.style.backgroundColor})`,
        )
      }
    })

    for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
      this.seamAnswerTensor[columnIndex] = {}
      this.seamAnswerTensor[columnIndex + 0.5] = {}
      for (let rowIndex = 0; rowIndex < this.rowCount; rowIndex++) {
        this.seamAnswerTensor[columnIndex][rowIndex + 0.5] = []
        this.seamAnswerTensor[columnIndex + 0.5][rowIndex] = []
      }
    }

    rawAnswers.forEach((rawAnswer) => {
      let text = extractTextFromHTML(rawAnswer.text)
      let action = null
      if (text.includes('{')) {
        action = text.replace(/[^{]*{/, '').replace(/}.*/, '')
        text = text.replace(/{.*}/, '')
      }
      this.addTranslations(rawAnswer.id, text)

      const position = this.locateAnswer(rawAnswer)
      this.answers[rawAnswer.id] = {
        id: rawAnswer.id,
        action,
        nextNodeId: MISSING_LINK,
        previousNodeId: MISSING_LINK,
        position,
        raw: rawAnswer,
        references: generateEmptyReferences(),
        order: -1,
        unavoidable:
          rawAnswer.style.borderColor === MiroLoader.Colors.UNAVOIDABLE,
      }

      try {
        this.seamAnswerTensor[position.column][position.row].push(rawAnswer.id)
      } catch (error) {
        console.error('Exception', error)
        console.error('Raw node', rawAnswer)
        throw Error(
          `[Miro.processData] Failed to place answer ${rawAnswer.id} in seam!`,
        )
      }
    })
    Object.values(this.seamAnswerTensor).forEach((columns) =>
      Object.values(columns).forEach((answerIds) => {
        ;(answerIds as Array<string>).sort((answerIdA, answerIdB) => {
          return MiroLoader.compareAnswersWithinSeam(
            this.answers[answerIdA],
            this.answers[answerIdB],
          )
        })
      }),
    )
    rawConnections.forEach((rawConnection) => {
      const startId = rawConnection.startWidgetId
      const endId = rawConnection.endWidgetId
      if (startId in this.contents) {
        if (!(endId in this.answers)) {
          console.error(
            `[Miro.processData] Connection (${rawConnection.id}) from content (${startId}) without matching answer (assumed ${endId})`,
            rawConnection,
          )
        }
        const content = this.contents[startId]
        const answer = this.answers[endId]

        content.answerIds.push(answer.id)
        answer.previousNodeId = content.id
        const { toConnectionPosition } = this.addReferenceCounts(
          content,
          answer,
        )
        if (answer.unavoidable) {
          switch (toConnectionPosition) {
            case ReferenceCounterPosition.Bottom:
              this.translations.en[answer.id] = '↑'
              break
            case ReferenceCounterPosition.Top:
              this.translations.en[answer.id] = '↓'
              break
            case ReferenceCounterPosition.Left:
              this.translations.en[answer.id] = '→'
              break
            case ReferenceCounterPosition.Right:
              this.translations.en[answer.id] = '←'
              break
          }
        }
      }
      if (startId in this.answers) {
        if (!(endId in this.contents)) {
          console.error('Raw connection', rawConnection)
          throw Error(
            `[Miro.processData] Connection (${rawConnection.id}) from answer (${startId}) without matching content (assumed ${endId})`,
          )
        }
        const answer = this.answers[startId]
        const content = this.contents[endId]

        answer.nextNodeId = endId
        this.addReferenceCounts(answer, content)
      }
    })

    Object.values(this.contents).forEach((content) => {
      let answerOrder = 0
      Object.entries(content.references).forEach(([direction, references]) => {
        references.sort((referenceA, referenceB) => {
          const [fromA, toA] = referenceA.split('-')
          const [fromB, toB] = referenceB.split('-')
          const nodeIdA = fromA === content.id ? toA : fromA
          const nodeIdB = fromB === content.id ? toB : fromB
          const answerA = this.answers[nodeIdA]
          const answerB = this.answers[nodeIdB]
          const deltaRow = answerA.position.row - answerB.position.row
          const deltaColumn = answerA.position.column - answerB.position.column
          if (deltaRow !== 0 || deltaColumn !== 0) {
            if (
              direction === ReferenceCounterPosition.Top ||
              direction === ReferenceCounterPosition.Bottom
            ) {
              return deltaColumn
            } else {
              return deltaRow
            }
          }
          return MiroLoader.compareAnswersWithinSeam(answerA, answerB)
        })

        references
          .map((reference) => reference.split('-')[1])
          .filter((nodeId) => nodeId !== content.id)
          .forEach((answerId, answerIndex) => {
            this.answers[answerId].order = answerOrder
            answerOrder += 1
          })
      })
    })
  }

  async load(url: string) {
    const data = await this.request(url)

    console.log(data)
    this.processData(data)
  }
}

export default MiroLoader
