import styled, { css } from 'styled-components'

import { GRAY_MEDIUM, shadow } from '../layout'

export const baseContentStyles = css`
  margin-top: 2rem;
  margin-left: 2rem;
`
export const BaseContent = styled.div`
  ${baseContentStyles}
`
const Content = styled(BaseContent)<{ inline?: boolean }>`
  background-color: white;
  padding: 0.7em 1em;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  box-shadow: ${shadow(GRAY_MEDIUM)};

  ${({ inline }) =>
    inline &&
    css`
      margin: 0;
    `}

  text-align: center;
  color: black;

  p {
    text-align: left;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  ol,
  ul {
    text-align: left;
    margin: 0;
  }
`

export const ContentTitle = styled.div`
  font-size: 150%;
  text-decoration: underline;
  margin-bottom: 1rem;
`
export const ContentParagraph = styled.div`
  margin-bottom: 1rem;
`

export default Content
