export const isTouch = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0

export const isUserAgentAndroid = (userAgent: string) =>
  /Android/i.test(userAgent)
export const isAndroid = isUserAgentAndroid(navigator.userAgent)
export const isUserAgentIOS = (userAgent: string) =>
  /iPhone|iPod/i.test(userAgent)
export const isIOS = isUserAgentIOS(navigator.userAgent)
