import styled from 'styled-components'
import BaseContainer from './BaseContainer'
import { BASE_FONT } from '../layout'

const FullscreenContainer = styled(BaseContainer)`
  min-height: 100vh;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  background-color: black;
  font-family: ${BASE_FONT};
`

export default FullscreenContainer
