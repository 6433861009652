import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import { models, RootModel } from './models'

export const store = init({
  models,
  redux: {
    devtoolOptions: {
      actionSanitizer: (action: any) => {
        if (
          action.type === 'miro/setData' ||
          action.type === 'transform/register'
        ) {
          return { ...action, payload: '<<OMITTED>>' }
        }
        if (action.type === 'collection') {
          return { ...action, data: '<<OMITTED>>' }
        }
        return action
      },
      stateSanitizer: (state: any) => {
        return { ...state, miro: '<<OMITTED>>' }
      },
    },
  },
})

// https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
export function observeStore<T>(
  select: (state: RootState) => T,
  onChange: (state: T) => void,
) {
  let currentState: T | undefined

  function handleChange() {
    let nextState = select(store.getState())
    if (nextState !== currentState) {
      currentState = nextState
      onChange(currentState)
    }
  }

  let unsubscribe = store.subscribe(handleChange)
  handleChange()
  return unsubscribe
}

export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
