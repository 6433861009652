import { Models } from '@rematch/core'
import { debug } from './debug'
import { history } from './history'
import { miro } from './miro'
import { transform } from './transform'
import { user } from './user'

export interface RootModel extends Models<RootModel> {
  debug: typeof debug
  history: typeof history
  miro: typeof miro
  transform: typeof transform
  user: typeof user
}

export const models: RootModel = { debug, history, miro, transform, user }
