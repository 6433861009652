import { createModel } from '@rematch/core'
import { gql } from '@apollo/client'
import i18next from 'i18next'

import {
  AnswerNode,
  ContentTypes,
  InteractiveComponent,
  MiroData,
} from '../data/miro-types'
import { DataReady } from '../data/data-state'

import { RootModel } from './models'
import { SetArtworkMutation } from '../generated/SetArtworkMutation'
import { SET_MOBILE_SETUP_MUTATION } from '../util/sharedGraphQL'
import {
  SetMobileSetup,
  SetMobileSetupVariables,
} from '../generated/SetMobileSetup'

const getAudioElement = (fileName: string): HTMLAudioElement | null => {
  if (!document.getElementById('app')) {
    // first render has not completed
    return null
  }
  const audioElement = document.getElementById(fileName)
  if (!audioElement) {
    throw Error(
      `[state/history.effects.activateAnswer] No audio element for file: ${fileName}`,
    )
  }
  return audioElement as HTMLAudioElement
}

const SET_ARTWORK_MUTATION = gql`
  mutation SetArtworkMutation($userId: String!, $artwork: String) {
    setArtwork(userId: $userId, artwork: $artwork) {
      _id
      currentArtwork
    }
  }
`

export interface HistoryState {
  blockIdTrack: Array<string>
  currentContentId: string | null
}

export const history = createModel<RootModel>()({
  state: {
    blockIdTrack: [],
    currentContentId: null,
  } as HistoryState, // initial state
  reducers: {
    setContentId(state, currentContentId: string) {
      return { ...state, currentContentId }
    },
    appendToBlockIdTrack(state, blockId: string) {
      return { ...state, blockIdTrack: [...state.blockIdTrack, blockId] }
    },
    removeFromBlockIdTrack(state, elementCount: number = 2) {
      if (state.blockIdTrack.length < elementCount) {
        throw Error(
          `[state/history.effects.undo] Can't remove ${elementCount} elements from block ID track, contains only ${state.blockIdTrack.length}!`,
        )
      }
      return {
        ...state,
        blockIdTrack: state.blockIdTrack.slice(
          0,
          state.blockIdTrack.length - elementCount,
        ),
      }
    },
    _setBlockIdTrack(state, blockIdTrack: Array<string>) {
      return { ...state, blockIdTrack }
    },
  },
  effects: (dispatch) => ({
    async _setArtwork(artworkId: string | null, state) {
      if (state.user._id === null) {
        return
      }

      // todo: handle errors
      const mutation = await window.apolloClient.mutate<SetArtworkMutation>({
        mutation: SET_ARTWORK_MUTATION,
        variables: {
          userId: state.user._id,
          artwork: artworkId,
        },
      })

      console.log('setArtwork mutation', mutation)
    },
    async _activateContent(
      {
        contentId,
        animation = true,
      }: { contentId: string; animation?: boolean },
      state,
    ) {
      const miro = (state.miro as DataReady<MiroData>).data
      const nextTile = miro.containers[miro.contents[contentId].tileId]
      dispatch.transform.flyToTile({ tile: nextTile.position, animation })
      dispatch.history.setContentId(contentId)

      const nextContent = miro.contents[contentId]
      if (nextContent.type === ContentTypes.Artwork) {
        dispatch.history._setArtwork(nextContent.id.toString())
      } else if (
        nextContent.type === ContentTypes.Interactive &&
        nextContent.component === InteractiveComponent.AUDIO &&
        nextContent.flags.includes('autoplay-navigation')
      ) {
        getAudioElement(nextContent.fileName)?.play()
      }
    },
    async activateAnswer(answer: AnswerNode, state) {
      if (answer.action) {
        const [
          action,
          parameter,
          ...additionalParameters
        ] = answer.action.split(':')
        if (action === 'lang') {
          i18next.changeLanguage(parameter)
        } else if (action === 'artwork' && parameter === 'off') {
          dispatch.history._setArtwork(null)
        } else if (action === 'audio' && parameter === 'off') {
          const fileName = additionalParameters[0]
          getAudioElement(fileName)?.pause()
        } else if (action === 'if') {
          // handled in Answer/useIsAnswerDisabled
        } else if (action === 'fullscreen') {
          const appElement = document.getElementById('app')
          if (!appElement) {
            throw Error(
              `[state/history.effects.activateAnswer] Can't get element to fullscreen by id: 'app'`,
            )
          }
          if (appElement.requestFullscreen) {
            appElement.requestFullscreen()
          } else {
            alert(
              'This browser unfortunately does not support fullscreen mode.',
            )
          }
        } else if (action === 'mobileSetup') {
          if (parameter === 'start') {
            dispatch.user.subscribeMobileSetup()
          } else if (parameter === 'end') {
            dispatch.user.setSubscribedMobileSetup(false)
          } else {
            throw Error(
              `[state/history.effects.activateAnswer] Illegal parameter for mobileSetup action: ${parameter}`,
            )
          }
        } else if (action === 'setMobileSetup') {
          window.apolloClient.mutate<SetMobileSetup, SetMobileSetupVariables>({
            mutation: SET_MOBILE_SETUP_MUTATION,
            variables: {
              userId: state.user._id,
              field: parameter,
              value: additionalParameters[0],
            },
          })
        } else {
          throw Error(
            `[state/history.effects.activateAnswer] Unknown action: ${answer.action}`,
          )
        }
      }

      dispatch.history.appendToBlockIdTrack(answer.previousNodeId)
      dispatch.history.appendToBlockIdTrack(answer.id)

      dispatch.history._activateContent({
        contentId: answer.nextNodeId,
      })
    },
    async goToContent({
      contentId,
      animation = true,
    }: {
      contentId: string
      animation?: boolean
    }) {
      dispatch.history.setContentId(contentId)
      dispatch.history._activateContent({ contentId, animation })
    },
    async undo(_, state) {
      const miro = (state.miro as DataReady<MiroData>).data
      if (state.history.currentContentId) {
        const currentContent = miro.contents[state.history.currentContentId]
        if (
          currentContent.type === ContentTypes.Interactive &&
          currentContent.component === InteractiveComponent.AUDIO &&
          currentContent.flags.includes('autoplay-navigation')
        ) {
          getAudioElement(currentContent.fileName)?.pause()
        }
      }
      const previousContent =
        miro.contents[
          state.history.blockIdTrack[state.history.blockIdTrack.length - 2]
        ]
      if (previousContent.type === ContentTypes.Artwork) {
        dispatch.history._setArtwork(null)
      }
      dispatch.history.removeFromBlockIdTrack() // throws if history is empty
      dispatch.history._activateContent({ contentId: previousContent.id })
    },
  }),
})
