import React, { ErrorInfo, lazy } from 'react'

const ErrorPage = lazy(() => import('./ErrorPage'))

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  { error?: Error; stack?: string }
> {
  static getDerivedStateFromError(error: Error) {
    return { error }
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ stack: errorInfo.componentStack })
  }
  render() {
    if (this.state?.error) {
      return (
        <ErrorPage
          title={'Fatal error'}
          error={this.state.error}
          stack={this.state.stack}
        />
      )
    }

    return this.props.children
  }
}
