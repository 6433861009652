import { createModel } from '@rematch/core'
import { throttle } from 'lodash'

import { Position } from '../data/miro-types'
import { DataState } from '../data/data-state'
import { columnIndexToPx, rowIndexToPx } from '../util/grid'

import { RootModel } from './models'
import { observeStore } from './store'
import { eventClientX, eventClientY, isMultiTouch } from '../util/events'

export const TRANSFORM_ANIMATION_LENGTH: number = 1000

interface Coordinates {
  x: number
  y: number
}

interface TransformState {
  scale: number
  translateX: number
  translateY: number
  transition: boolean
  _reenableTransition: boolean
  containerSize: Coordinates
}

export const transform = createModel<RootModel>()({
  state: {
    scale: 1,
    translateX: 0,
    translateY: 0,
    transition: true,
    _reenableTransition: false,
    containerSize: {
      x: NaN,
      y: NaN,
    },
  } as TransformState,
  reducers: {
    setContainerSize(state, containerSize: Coordinates) {
      return { ...state, containerSize }
    },
    setScale(state, scale: number) {
      return { ...state, scale }
    },
    zoom(state, { deltaZoom, jump }: { deltaZoom: number; jump?: boolean }) {
      const nextScale = Math.min(1, state.scale * (1 + deltaZoom))
      const deltaScale = state.scale / nextScale - 1
      const deltaX =
        deltaScale *
        (-state.translateX +
          0.5 * window.innerWidth -
          0.5 * state.containerSize.x)
      const deltaY =
        deltaScale *
        (-state.translateY +
          0.5 * window.innerHeight -
          0.5 * state.containerSize.y)
      return {
        ...state,
        scale: nextScale,
        translateX: state.translateX + deltaX,
        translateY: state.translateY + deltaY,
        transition: !jump,
        _reenableTransition: jump || false,
      }
    },
    setTranslateXY(state, { x, y }: Coordinates) {
      return { ...state, translateX: x, translateY: y }
    },
    translate(state, { x, y }: Coordinates) {
      return {
        ...state,
        translateX: state.translateX + x,
        translateY: state.translateY + y,
      }
    },
    setTransition(state: TransformState, transition: boolean) {
      return {
        ...state,
        transition,
        _reenableTransition: false,
      }
    },
  },
  effects: (dispatch) => ({
    register(domElement: HTMLElement, state) {
      let animationFrameHandle: number | null = null
      observeStore<TransformState>(
        (observedState) => observedState.transform,
        (observedTransformState) => {
          if (animationFrameHandle) {
            cancelAnimationFrame(animationFrameHandle)
          }
          animationFrameHandle = requestAnimationFrame(() => {
            if (observedTransformState.transition) {
              domElement.style.transition = `transform ease-in-out ${TRANSFORM_ANIMATION_LENGTH}ms`
            } else {
              domElement.style.removeProperty('transition')
            }
            domElement.style.transform = `translate3d(${observedTransformState.translateX}px, ${observedTransformState.translateY}px, 0) scale(${observedTransformState.scale})`
            domElement.style.setProperty(
              '--line-stroke-width',
              `${2 / observedTransformState.scale}px`,
            )
            animationFrameHandle = null
            if (observedTransformState._reenableTransition) {
              dispatch.transform.setTransition(false)
            }
          })
        },
      )

      const scrollHandler = (event: WheelEvent) => {
        if ((event.target as HTMLElement)?.dataset?.scroll === 'allow') {
          return
        }

        if (event.deltaY !== 0) {
          dispatch.transform.zoom({
            deltaZoom: (event.deltaY > 0 ? -1 : 1) * 0.03,
            jump: true,
          })
        }
      }
      let lastPinchLength: number | null = null
      const pinchHandler = (event: TouchEvent): undefined | boolean => {
        if (event.touches.length !== 2) {
          return false
        }
        const [touchA, touchB] = Array.from(event.touches)
        const pinchLength = Math.hypot(
          touchA.clientX - touchB.clientX,
          touchA.clientY - touchB.clientY,
        )
        if (lastPinchLength) {
          const pinchLengthDelta = pinchLength - lastPinchLength

          dispatch.transform.setTransition(false)
          dispatch.transform.zoom({
            deltaZoom:
              Math.sign(pinchLengthDelta) *
              Math.sqrt(Math.abs(pinchLengthDelta)) *
              0.01,
            jump: true,
          })
          setImmediate(() => dispatch.transform.setTransition(true))
        }
        lastPinchLength = pinchLength
        // alert(pinchLength)
        event.preventDefault()
        event.stopPropagation()
        return false
      }
      let mousePosition: Coordinates | null = null
      const mouseDownHandler = (event: MouseEvent | TouchEvent) => {
        if (isMultiTouch(event)) {
          return
        }

        document.body.style.cursor = 'grabbing'
        document.body.style.userSelect = 'none'

        mousePosition = {
          x: eventClientX(event),
          y: eventClientY(event),
        }
        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('touchmove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
        document.addEventListener('touchend', mouseUpHandler)

        dispatch.transform.setTransition(false)
      }
      const mouseMoveHandler = (event: MouseEvent | TouchEvent) => {
        if (mousePosition === null) {
          console.warn(
            '[TransformManager.mouseMoveHandler] Called before mouse position was registered',
          )
          return
        }
        if (isMultiTouch(event)) {
          return
        }

        dispatch.transform.translate({
          x: eventClientX(event) - mousePosition.x,
          y: eventClientY(event) - mousePosition.y,
        })

        mousePosition.x = eventClientX(event)
        mousePosition.y = eventClientY(event)
      }
      const mouseUpHandler = () => {
        mousePosition = null
        lastPinchLength = null

        document.body.style.cursor = 'grab'
        document.body.style.removeProperty('user-select')

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)

        dispatch.transform.setTransition(true)
      }

      window.addEventListener('wheel', scrollHandler)
      document.addEventListener('mousedown', mouseDownHandler)
      document.addEventListener('touchstart', mouseDownHandler)
      document.addEventListener('touchmove', pinchHandler, {
        passive: false,
      })

      const viewportMeta = document.querySelector('meta[name="viewport"]')
      if (!viewportMeta) {
        throw Error(`[state/transform.effects.register] No viewport meta tag!`)
      }
      viewportMeta.setAttribute(
        'content',
        viewportMeta.getAttribute('content') +
          ', minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
      )

      const handleResize = throttle(() => {
        dispatch.transform.returnToLastTile()
      }, 250)
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('wheel', scrollHandler)
        document.removeEventListener('mousedown', mouseDownHandler)
        document.removeEventListener('touchstart', mouseDownHandler)
        document.removeEventListener('touchmove', pinchHandler)
        window.removeEventListener('resize', handleResize)
      }
    },
    async flyToTile({
      tile,
      animation = true,
    }: {
      tile: Position
      animation?: boolean
    }) {
      if (!animation) {
        dispatch.transform.setTransition(false)
      }
      dispatch.transform.setScale(1)
      dispatch.transform.setTranslateXY({
        x: columnIndexToPx(tile.column),
        y: rowIndexToPx(tile.row),
      })

      if (!animation) {
        setImmediate(() => dispatch.transform.setTransition(true))
      }
    },
    async returnToLastTile(_: void, state) {
      if (state.history.currentContentId === null) {
        throw Error(
          `[state/transform.effects.returnToLastTile] No content ID in history!`,
        )
      }
      const { miro: miroRequest } = state
      if (miroRequest.state !== DataState.READY) {
        throw Error(
          `[state/transform.effects.returnToLastTile] Miro state not ready!`,
        )
      }
      const miro = miroRequest.data

      dispatch.transform.flyToTile({
        tile:
          miro.containers[miro.contents[state.history.currentContentId].tileId]
            .position,
      })
    },
  }),
})
